import React from 'react';
import Icon from '@/components/Icon';
import { useCurrencyConfig } from '@/stores';

export const CurrencySymbolMap = new Map<string, string>([
  ['EUR', '€'],
  ['CNY', '¥'],
  ['CAD', 'C$'],
  ['AUD', 'A$'],
  ['GBP', '£'],
]);

export const CurrencyIconMap = new Map<string, any>([
  [
    'AUD',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/zp0eHDKkDoI5qYHGIL-Qr1726478020890.svg',
  ],
  [
    'CAD',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/mEiErYotuOHIa-AVWG_JU1726478099030.svg',
  ],
  [
    'CNY',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/qEbfvOwTf6XLjY1U1lSpY1726478121572.svg',
  ],
  [
    'EUR',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/75fNxU4h5GGzSgVDXf_b71726478150453.svg',
  ],
  [
    'GBP',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/vpqc5YZ-lSaGRjvnPg87b1726478175198.svg',
  ],
  [
    'USD',
    'https://hexapus.oss-us-west-1.aliyuncs.com/upload-assets/_k1BdEKHstk3XlWc_Czmn1726478201478.svg',
  ],
]);

/**
 * 支付方式
 */
export enum PayChannel {
  /** 现金 paypal 含税费 */
  Cash = 1,
  /** 现金 信用卡(stripe被封了) */
  Stripe = 2,
  // 通过AirWallex支付
  AirWallex = 3,
  // AfterPay
  AfterPay = 4,
  /** klarna */
  Klarna = 5,
  /** 金币 */
  Gold = 10,
  /** 积分就是钻石 */
  Integral = 11,
  /** 钻石抵扣完了 */
  Deduct = -1,
}

// 是否是现金支付 (用于确定物流)
export const isCashPayChannel = (payChannel: PayChannel) =>
  [
    PayChannel.Cash,
    PayChannel.Stripe,
    PayChannel.AirWallex,
    PayChannel.AfterPay,
    PayChannel.Klarna,
  ].includes(payChannel);

// 是否需要税费
export const isNeedPayFeePayChannel = (payChannel: PayChannel) => {
  return [PayChannel.Cash, PayChannel.AfterPay, PayChannel.Klarna].includes(
    payChannel,
  );
};

const commonFunction = (
  price?: number,
  currency?: string,
  icon?: string,
): React.ReactNode => {
  const { currencyRate } = useCurrencyConfig.getState();

  const actualPrice =
    currency == null
      ? (price ?? 0) * (currencyRate.exchange_rate ?? 1)
      : price ?? 0;

  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      {icon ? (
        <Icon
          id={icon}
          className="pay-channel-icon"
          style={{ fontSize: '1.4em', marginRight: 4 }}
        />
      ) : (
        <span className="pay-channel-icon">
          {CurrencySymbolMap.get(currency ?? currencyRate.to_currency!) ?? '$'}
        </span>
      )}
      <span className="pay-channel-num">{actualPrice.toFixed(2)}</span>
    </span>
  );
};

export const PayChannelLabelMap = new Map<PayChannel, string>([
  [PayChannel.Cash, '现金支付'],
  [PayChannel.Stripe, '信用卡支付'],
  [PayChannel.AirWallex, '信用卡支付'],
  [PayChannel.AfterPay, '先买后付'],
  [PayChannel.Klarna, 'Klarna支付'],
  [PayChannel.Gold, '金币支付'],
  // 积分是钻石
  [PayChannel.Integral, '积分兑换'],
  [PayChannel.Deduct, '钻石抵扣'],
]);

export const PayChannelFormatorMap = new Map<
  PayChannel,
  (price?: number, currency?: string) => React.ReactNode
>([
  [PayChannel.Cash, (price, currency) => commonFunction(price, currency)],
  [PayChannel.Deduct, (price, currency) => commonFunction(price, currency)],
  [PayChannel.Stripe, (price, currency) => commonFunction(price, currency)],
  [PayChannel.AirWallex, (price, currency) => commonFunction(price, currency)],
  [PayChannel.AfterPay, (price, currency) => commonFunction(price, currency)],
  [PayChannel.Klarna, (price, currency) => commonFunction(price, currency)],
  [PayChannel.Gold, (price) => commonFunction(price, '-', '#hxicon-gold')],
  [
    PayChannel.Integral,
    (price) => commonFunction(price, '-', '#hxicon-diamond'),
  ],
]);

/**
 * 邮费费用类型
 */
export enum LogisticsType {
  /** 满五包邮 */
  OverFive = 1,
  /** 自定义邮费 */
  Normal,
}

// 金币充值，PalFee 税费，一般是4%或后端直接获取
// export const PayPalFee = 0.04;
export const PayPalFee = 0.04;
